var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex w-100",attrs:{"lg":"7"}},[_c('b-carousel',{attrs:{"id":"carousel-example-generic","controls":"","indicators":""}},[_c('b-carousel-slide',{attrs:{"img-src":require('@/assets/images/pages/login-v2.svg')}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-img',{staticClass:"w-50",attrs:{"src":_vm.imgLogo,"alt":"logo"}})],1),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('validation-provider',{attrs:{"name":"Matricula","vid":"rcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{attrs:{"label":"Matrícula","label-for":"rcode"}},[_c('b-form-input',{class:classes,attrs:{"id":"rcode","name":_vm.rcode,"placeholder":"0000"},model:{value:(_vm.rcode),callback:function ($$v) {_vm.rcode=$$v},expression:"rcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])],1)]}}],null,true)}),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")])]),_c('validation-provider',{attrs:{"name":"Senha","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",class:classes,attrs:{"id":"password","type":_vm.passwordFieldType,"name":"password","placeholder":"******"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",class:classes,attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Lembrar-me ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[(_vm.load)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.load)?_c('span',[_vm._v(" Logando...")]):_vm._e(),(!_vm.load)?_c('span',[_vm._v("Login")]):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }